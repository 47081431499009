import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col, Table } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"


const IndexPage = () => {
    const root = "/img/download/leaders"

    return (
        <Layout pageTitle="Leaders - Templates">
            <Row>
                <Col>
                    <Link to="/leaders">Back</Link>
                </Col>
            </Row>


            <ImageDownload
                root={root}
                name="Your Club Leader"
                filename="club-leader"
                text="Use this to announce yourself as club leader and share about your club!"
                text2=""
                squareHref="https://www.canva.com/design/DAFqfzXsrjg/bZh8ALp7XgdPFK-5MmAvjg/view?utm_content=DAFqfzXsrjg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFHyTM9Ou0/pFWU8-OdnY2wbqAUYxembA/view?utm_content=DAFHyTM9Ou0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://womensbusiness.club/leaders"
            />


           
        </Layout>
    )
}

export default IndexPage
